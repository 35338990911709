class LocalStorage {
    setApiKey(apiKey) {
      localStorage.setItem("API_KEY", apiKey);
    }
  
    getApiKey() {
      return localStorage.getItem("API_KEY");
    }
  
    setUserDetails(userDetails) {
      localStorage.setItem("USER", JSON.stringify(userDetails));
    }
  
    getUserDetails() {
      const user = localStorage.getItem("USER");
      return JSON.parse(user);
    }
  
    setSetting(setting) {
      localStorage.setItem("SETTING", JSON.stringify(setting));
    }
  
    getSetting() {
      const setting = localStorage.getItem("SETTING");
      return JSON.parse(setting);
    }
  
    setUserLocation(location) {
      localStorage.setItem("LOCATION", location);
    }
  
    getUserLocation() {
      const location = localStorage.getItem("LOCATION");
      return location;
    }
  
    setCartCount(cartCount) {
      localStorage.setItem("CART_COUNT", cartCount);
    }
  
    getCartCount() {
      const cartCount = localStorage.getItem("CART_COUNT");
      if (cartCount) {
        return cartCount;
      }
      return 0;
    }
  
    setHome(homeData) {
      localStorage.setItem("HOME", JSON.stringify(homeData));
    }
  
    getHome() {
      const homeData = localStorage.getItem("HOME");
      return JSON.parse(homeData);
    }
  
    logout() {
      localStorage.removeItem("API_KEY");
      localStorage.removeItem("USER");
      localStorage.removeItem("CART_COUNT");
    }
  }
  
  export default new LocalStorage();