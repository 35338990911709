import React, { useEffect, useState } from "react";
import { getCategories, getCountries } from "../../axios/ServerRequest";
import LocalStorage from "../../utils/LocalStorage";

const Profile = (props) => {
  const user = LocalStorage.getUserDetails();
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState(user.name);
  const [nameError, setNameError] = useState("");
  const [company, setCompany] = useState(user.company);
  const [website, setWebsite] = useState(user.website);
  const [phone, setPhone] = useState(user.phone);
  const [phoneError, setPhoneError] = useState("");
  const [whatsapp, setWhatsapp] = useState(user.whatsapp);
  const [whatsappError, setWhatsappError] = useState("");
  const [country, setCountry] = useState(user.country);
  const [countryError, setCountryError] = useState("");

  const [countries, setCountries] = useState([]);

  const [email, setEmail] = useState(user.email);
  const [emailError, setEmailError] = useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchCountry();
  }, []);

  const fetchCountry = async () => {
    await getCountries()
      .then((res) => {
        if (res.status === 200) {
          setCountries(res.data.country);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removerError = () => {
    setNameError("");
    setEmailError("");
    setPhoneError("");
    setWhatsappError("");
    setCountryError("");
  };

  return (
    <div>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <strong>Profile</strong>
        {/* <button
          className="btn btn-sm btn-primary"
          onClick={() => setDisabled(false)}
        >
          <i className="fa fa-pencil" aria-hidden="true"></i>
          <span> Edit</span>
        </button> */}
      </div>
      <hr />
      <div>
        <div className="form-group mb-3">
          <label>Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="Enter your name"
            autoComplete="off"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            autoFocus
            disabled={disabled}
          />

          <div className="fs-small text-danger">{nameError}</div>
        </div>

        <div className="form-group mb-3">
          <label>Email address</label>
          <input
            type="text"
            className="form-control"
            id="email"
            placeholder="Enter email"
            autoComplete="off"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            disabled={disabled}
          />
          <div className="fs-small text-danger">{emailError}</div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label>Phone</label>
              <input
                type="text"
                className="form-control"
                id="phone"
                placeholder="Enter Phone"
                autoComplete="off"
                value={phone}
                maxLength={12}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                disabled={disabled}
              />
              <div className="fs-small text-danger">{phoneError}</div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label>Whatsapp</label>
              <input
                type="text"
                className="form-control"
                id="whatsapp"
                placeholder="Enter Whatsapp"
                autoComplete="off"
                value={whatsapp}
                maxLength={12}
                onChange={(e) => {
                  setWhatsapp(e.target.value);
                }}
                disabled={disabled}
              />
              <div className="fs-small text-danger">{whatsappError}</div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label>Company</label>
              <input
                type="text"
                className="form-control"
                id="company"
                placeholder="Enter Company"
                autoComplete="off"
                value={company}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                disabled={disabled}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group mb-3">
              <label>Website</label>
              <input
                type="text"
                className="form-control"
                id="website"
                placeholder="Enter Website"
                autoComplete="off"
                value={website}
                onChange={(e) => {
                  setWebsite(e.target.value);
                }}
                disabled={disabled}
              />
            </div>
          </div>
        </div>

        <div className="form-group mb-3">
          <label>Country</label>
          <select
            className="form-control form-select"
            aria-label="Default select example"
            onChange={(e) => setCountry(e.target.value)}
            value={country}
            disabled={disabled}
          >
            <option>Select Country</option>
            {countries.map((item, index) => {
              return (
                <option value={item.title} key={index}>
                  {item.name}
                </option>
              );
            })}
            
          </select>
          <div className="fs-small text-danger">{countryError}</div>
        </div>

        <div className="d-flex flex-row align-items-center flex-wrap mb-5">
          {/* <button
            disabled={loading}
            type="submit"
            className="btn btn-primary"
            onClick={() => {
              removerError();
              // userRegistration();
            }}
          >
            {loading ? (
              <span className="spinner-border spinner-border-sm"></span>
            ) : (
              "Submit"
            )}
          </button>
          <button
            disabled={loading}
            type="submit"
            className="btn btn-warning ms-3"
            onClick={() => {
              removerError();
              setDisabled(true);
            }}
          >
            Cancel
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Profile;
