import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Preloader from "./components/Common/Preload";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Aos from "aos";
import "./assets/css/font-awesome.min.css";
import "./assets/css/animate.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Login from "./views/Login";
import Register from "./views/Register";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoute from "./Route/ProtectedRoute";
import RFQ from "./views/RFQ";
import Dashboard from "./views/Dashboard";
import DataProtection from "./views/DataProtection";
import TermCondition from "./views/TermCondition";
import Disclaimer from "./views/Disclaimer";
import Premium from "./views/Premium";
import ContactUs from "./components/Common/ContactUs";

const DefaultLayout = React.lazy(() => import("./layouts/BaseLayout"));
const Home = React.lazy(() => import("./views/Home"));

class App extends React.Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    Aos.init();
    this.demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };

  hashLinkScroll = () => {
    const { hash } = window.location;
    if (hash !== "") {
      setTimeout(() => {
        const id = hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) element.scrollIntoView();
      }, 0);
    }
  };

  render() {
    return (
      <PayPalScriptProvider
        options={{
          "client-id":
            "AS1y2Gzs0-jVUnS_wRw2oNjl18t4J4-3ylyUW2eSN_157QvxFe5AkXv7XHITSgpr5V8-hcR4BYSEasKq",
        }}
      >
        <Router onUpdate={this.hashLinkScroll}>
          <Suspense fallback={<Preloader />}>
            <Routes>
              <Route path="/" element={<DefaultLayout />}>
                <Route index path="" name="Home Page" element={<Home />} />
                <Route path="home" name="Home" element={<Home />} />
                <Route
                  path="rfq"
                  name="RFQ"
                  element={
                    <ProtectedRoute>
                      <RFQ />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="dashboard"
                  name="Dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route path="login" name="Login" element={<Login />} />

              <Route
                path="/register"
                name="BuyerRegister"
                element={<Register />}
              />
              {/* <Route
                path="/premium-plan"
                name="BuyerRegister"
                element={<Premium />}
              /> */}
              <Route
                path="/contact-us"
                name="Contact us"
                element={<ContactUs />}
              />
              <Route
                path="/privacy-policy"
                name="Term Condition"
                element={<TermCondition />}
              />

              <Route
                path="/cookie-policy"
                name="Disclaimer"
                element={<Disclaimer />}
              />
              <Route
                path="/data-protection"
                name="Data Protection"
                element={<DataProtection />}
              />
            </Routes>
          </Suspense>
        </Router>
      </PayPalScriptProvider>
    );
  }
}

export default App;
