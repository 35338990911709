import React, { useEffect, useState } from "react";
import "../../assets/css/login.css";
import {
  getCategories,
  getCountries,
  manufacturerRegister,
  userRegister,
} from "../../axios/ServerRequest";
import { ToastContainer, toast } from "react-toastify";

import { Multiselect } from "multiselect-react-dropdown";
import LocalStorage from "../../utils/LocalStorage";

const Register = () => {
  const [role, setRole] = useState(
    window.location.href.split("=")[1]
      ? window.location.href.split("=")[1]
      : "seller"
  );
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [company, setCompany] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [whatsappError, setWhatsappError] = useState("");
  const [country, setCountry] = useState("");
  const [countryError, setCountryError] = useState("");
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [countries, setCountries] = useState([]);
  const [categories, setCategories] = useState([]);
  const [city, setCity] = useState("");
  const [cityError, setCityError] = useState("");
  const [about, setAbout] = useState("");
  const [aboutError, setAboutError] = useState("");
  const [targetCountry, setTargetCountry] = useState("");
  const [targetCountryError, setTargetCountryError] = useState("");
  const [certification, setCertification] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);
  const [buyer, setBuyer] = useState(
    window.location.href.split("=")[1] === "buyer" ? true : false
  );

  useEffect(() => {
    fetchCategory();
    fetchCountry();
  }, []);

  const fetchCategory = async () => {
    await getCategories()
      .then((res) => {
        if (res.status === 200) {
          setCategories(res.data.category);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchCountry = async () => {
    await getCountries()
      .then((res) => {
        if (res.status === 200) {
          setCountries(res.data.country);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function onSelectCountry(selectedList, selectedItem) {
    let countryList = selectedList.map((item) => {
      return item["name"];
    });
    setTargetCountry(countryList.toString());
  }

  function onRemoveCountry(selectedList, removedItem) {
    let countryList = selectedList.map((item) => {
      return item["name"];
    });
    setTargetCountry(countryList.toString());
  }
  function onSelectCategory(selectedList, selectedItem) {
    let categoryList = selectedList.map((item) => {
      return item["title"];
    });
    setCategory(categoryList.toString());
  }

  function onRemoveCategory(selectedList, removedItem) {
    let categoryList = selectedList.map((item) => {
      return item["title"];
    });
    setCategory(categoryList.toString());
  }

  const saveManufacturer = async () => {
    if (name.length < 3) {
      setNameError("Please enter valid name");
      return;
    } else if (email.length < 10) {
      setEmailError("Please enter valid Email");
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("Please enter valid Email");
      return;
    } else if (phone.length < 10) {
      setPhoneError("Please enter valid phone number");
      return;
    } else if (whatsapp.length < 10) {
      setWhatsappError("Please enter valid whatsapp number");
      return;
    } else if (country.length === 0) {
      setCountryError("Please select your country");
      return;
    } else if (city.length === 0) {
      setCityError("Please enter your city");
      return;
    } else if (targetCountry.length === 0) {
      setTargetCountryError(
        "Please select target countries to sell your product"
      );
      return;
    } else if (category.length === 0) {
      setCategoryError("Please enter categories of your products");
      return;
    } else if (about.length === 0) {
      setAboutError("Please enter about your business");

      return;
    } else {
      const data = {
        name: name,
        email: email,
        phone: phone,
        whatsapp: whatsapp,
        company: company,
        website: website,
        country: country,
        categories: category,
        city: city,
        about: about,
        targetCountry: targetCountry,
        certification: certification,
      };

      setLoading(true);

      await manufacturerRegister(data)
        .then((res) => {
          if (res.status === 201) {
            localStorage.setItem("message", "You have Successfully Register");
            toast("You have successfully register");
            window.location.href = "/";
          }
          setLoading(false);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            const error = err.response.data.error;
            if (error.email) {
              setEmailError(error.email[0]);
              window.scroll(0, 0);
            }
            if (error.phone) {
              setPhoneError(error.phone[0]);
              window.scroll(0, 0);
            }
          }
        });
      setLoading(false);
    }
  };

  const userRegistration = async () => {
    if (name.length < 3) {
      setNameError("Please enter valid name");
      return;
    } else if (email.length < 10) {
      setEmailError("Please enter valid Email");
      return;
    } else if (password.length < 6) {
      setPasswordError("Please enter 6 characters’  password");
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("Please enter valid Email");
      return;
    } else if (phone.length < 10) {
      setPhoneError("Please enter valid phone number");
      return;
    } else if (whatsapp.length < 10) {
      setWhatsappError("Please enter valid whatsapp number");
      return;
    } else if (country.length === 0) {
      setCountryError("Please select your country");
      return;
    } else {
      const data = {
        name: name,
        email: email,
        password: password,
        phone: phone,
        whatsapp: whatsapp,
        company: company,
        website: website,
        country: country,
      };
      setLoading(true);

      await userRegister(data)
        .then((res) => {
          if (res.status === 201) {
            toast("You have successfully register");
            const data = res.data;
            LocalStorage.setApiKey(data.token_type + " " + data.token);
            LocalStorage.setUserDetails(data.user);
            localStorage.setItem("message", "You have Successfully Register");
            window.location.href = "/";
          }

          setLoading(false);
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            const error = err.response.data.error;
            if (error.email) {
              setEmailError(error.email[0]);
              window.scroll(0, 0);
            }
            if (error.phone) {
              setPhoneError(error.phone[0]);
              window.scroll(0, 0);
            }
          }
        });
      setLoading(false);
    }
  };

  const removerError = () => {
    setNameError("");
    setPasswordError("");
    setEmailError("");
    setPhoneError("");
    setWhatsappError("");
    setCountryError("");
    setCityError("");
    setTargetCountryError("");
    setCategoryError("");
    setAboutError("");
  };
  const resetData = () => {
    setName("");
    setPassword("");
    setEmail("");
    setPhone("");
    setWhatsapp("");
    setCountry("");
    setCity("");
    setTargetCountry("");
    setCategory("");
    setAbout("");
    setWebsite("");
    setCompany("");
    setCertification("");
  };

  return (
    <div className="login container">
      {buyer ? (
        <div className="row ">
          <div className="col-md-6">
            <div className="login-container">
              <div className="panel left-panel">
                <div className="content">
                  <h4>
                    <b>Buy Through us</b>
                  </h4>
                  <ul className="d-flex flex-column align-items-start">
                    <li>
                      <i
                        className="fa fa-circle"
                        aria-hidden="true"
                        style={{ fontSize: 12, marginRight: 5 }}
                      ></i>
                      <strong
                        className="me-3"
                        style={{ fontWeight: 600, fontSize: 14 }}
                      >
                        Quality-Highest
                      </strong>
                    </li>
                    <li>
                      <i
                        className="fa fa-circle"
                        aria-hidden="true"
                        style={{ fontSize: 12, marginRight: 5 }}
                      ></i>
                      <strong
                        className="me-3"
                        style={{ fontWeight: 600, fontSize: 14 }}
                      >
                        Cost-Lowest
                      </strong>
                    </li>
                    <li>
                      <i
                        className="fa fa-circle"
                        aria-hidden="true"
                        style={{ fontSize: 12, marginRight: 5 }}
                      ></i>
                      <strong
                        className="me-3"
                        style={{ fontWeight: 600, fontSize: 14 }}
                      >
                        Delivery-Quickest
                      </strong>
                    </li>
                  </ul>
                </div>
                <img
                  src={require("../../assets/images/loginbg.png")}
                  className="image"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 pt-5">
            <form action="#" className="sign-in-form buyer auth-form">
              <img
                src={require("../../assets/images/logo.png")}
                className="img-fluid mb-3"
                style={{ maxWidth: 100 }}
              />
              <div>
                <div className="form-group mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter your name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    autoFocus
                  />

                  <div className="fs-small text-danger">{nameError}</div>
                </div>

                <div className="form-group mb-3">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <div className="fs-small text-danger">{emailError}</div>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputPassword1">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Password"
                    autoComplete="off"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                  <div className="fs-small text-danger">{passwordError}</div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Enter Phone"
                        autoComplete="off"
                        value={phone}
                        maxLength={12}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                      <div className="fs-small text-danger">{phoneError}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Whatsapp</label>
                      <input
                        type="text"
                        className="form-control"
                        id="whatsapp"
                        placeholder="Enter Whatsapp"
                        autoComplete="off"
                        value={whatsapp}
                        maxLength={12}
                        onChange={(e) => {
                          setWhatsapp(e.target.value);
                        }}
                      />
                      <div className="fs-small text-danger">
                        {whatsappError}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Company</label>
                      <input
                        type="text"
                        className="form-control"
                        id="company"
                        placeholder="Enter Company"
                        autoComplete="off"
                        value={company}
                        onChange={(e) => {
                          setCompany(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Website</label>
                      <input
                        type="text"
                        className="form-control"
                        id="website"
                        placeholder="Enter Website"
                        autoComplete="off"
                        value={website}
                        onChange={(e) => {
                          setWebsite(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mb-3">
                  <label>Country</label>
                  <select
                    className="form-control form-select"
                    aria-label="Default select example"
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                  >
                    <option>Select Country</option>
                    {countries.map((item, index) => {
                      return (
                        <option value={item.title} key={index}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="fs-small text-danger">{countryError}</div>
                </div>

                <div className="d-flex flex-row align-items-center flex-wrap mb-5">
                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      removerError();
                      userRegistration();
                    }}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <div className="ms-2 fs-12">
                    Already have an account? <a href="/login">Login</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-6">
            <div className="login-container">
              <div className="panel left-panel">
                <div className="content">
                  <h4>
                    <b>Sell Through Us</b>
                  </h4>
                  <ul className="d-flex flex-column align-items-start">
                    <li>
                      <i
                        className="fa fa-circle"
                        aria-hidden="true"
                        style={{ fontSize: 12, marginRight: 5 }}
                      ></i>
                      <strong
                        className="me-3"
                        style={{ fontWeight: 600, fontSize: 14 }}
                      >
                        Get Visibility
                      </strong>
                    </li>
                    <li>
                      <i
                        className="fa fa-circle"
                        aria-hidden="true"
                        style={{ fontSize: 12, marginRight: 5 }}
                      ></i>
                      <strong
                        className="me-3"
                        style={{ fontWeight: 600, fontSize: 14 }}
                      >
                        Get Branded
                      </strong>
                    </li>

                    <li>
                      <i
                        className="fa fa-circle"
                        aria-hidden="true"
                        style={{ fontSize: 12, marginRight: 5 }}
                      ></i>
                      <strong
                        className="me-3"
                        style={{ fontWeight: 600, fontSize: 14 }}
                      >
                        Go Global
                      </strong>
                    </li>
                  </ul>
                </div>
                <img
                  src={require("../../assets/images/loginbg.png")}
                  className="image"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-md-6 pt-5 pb-5">
            <form action="#" className="sign-in-form seller auth-form">
              <img
                src={require("../../assets/images/logo.png")}
                className="img-fluid mb-3"
                style={{ maxWidth: 100 }}
              />
              <div>
                <div className="form-group mb-3">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Enter your name"
                    autoComplete="off"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    autoFocus
                  />

                  <div className="fs-small text-danger">{nameError}</div>
                </div>

                <div className="form-group mb-3">
                  <label>Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Enter email"
                    autoComplete="off"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                  <div className="fs-small text-danger">{emailError}</div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        id="phone"
                        placeholder="Enter Phone"
                        autoComplete="off"
                        value={phone}
                        maxLength={12}
                        onChange={(e) => {
                          setPhone(e.target.value);
                        }}
                      />
                      <div className="fs-small text-danger">{phoneError}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Whatsapp</label>
                      <input
                        type="text"
                        className="form-control"
                        id="whatsapp"
                        placeholder="Enter Whatsapp"
                        autoComplete="off"
                        value={whatsapp}
                        maxLength={12}
                        onChange={(e) => {
                          setWhatsapp(e.target.value);
                        }}
                      />
                      <div className="fs-small text-danger">
                        {whatsappError}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Company</label>
                      <input
                        type="text"
                        className="form-control"
                        id="company"
                        placeholder="Enter Company"
                        autoComplete="off"
                        value={company}
                        onChange={(e) => {
                          setCompany(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Website</label>
                      <input
                        type="text"
                        className="form-control"
                        id="website"
                        placeholder="Enter Website"
                        autoComplete="off"
                        value={website}
                        onChange={(e) => {
                          setWebsite(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>Country</label>
                      <select
                        className="form-control form-select"
                        aria-label="Default select example"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      >
                        <option selected>Open this select menu</option>
                        {countries.map((item, index) => {
                          return (
                            <option value={item.title} key={index}>
                              {item.name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="fs-small text-danger">{countryError}</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        id="city"
                        placeholder="Enter City"
                        autoComplete="off"
                        value={city}
                        onChange={(e) => {
                          setCity(e.target.value);
                        }}
                      />
                      <div className="fs-small text-danger">{cityError}</div>
                    </div>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label>Target Country</label>
                  {countries.length > 0 && (
                    <Multiselect
                      options={countries} // Options to display in the dropdown
                      onSelect={onSelectCountry} // Function will trigger on select event
                      onRemove={onRemoveCountry} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />
                  )}

                  <div className="fs-small text-danger">
                    {targetCountryError}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label>Categories</label>
                  {categories.length > 0 && (
                    <Multiselect
                      options={categories} // Options to display in the dropdown
                      onSelect={onSelectCategory} // Function will trigger on select event
                      onRemove={onRemoveCategory} // Function will trigger on remove event
                      displayValue="title" // Property name to display in the dropdown options
                    />
                  )}
                  <div className="fs-small text-danger">{categoryError}</div>
                </div>

                <div className="form-group mb-3">
                  <label>Certification</label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    placeholder="Enter Certifications"
                    autoComplete="off"
                    value={certification}
                    onChange={(e) => {
                      setCertification(e.target.value);
                    }}
                  />
                </div>

                <div className="form-group mb-3">
                  <label>About Business</label>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    value={about}
                    onChange={(e) => setAbout(e.target.value)}
                  ></textarea>
                  <div className="fs-small text-danger">{aboutError}</div>
                </div>

                <div className="d-flex flex-row align-items-center flex-wrap mb-5">
                  <button
                    type="submit"
                    disabled={loading}
                    className="btn btn-primary"
                    onClick={() => {
                      removerError();
                      saveManufacturer();
                    }}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      "Submit"
                    )}
                  </button>
                  <div className="ms-2 fs-12">
                    Already have an account? <a href="/login">Login</a>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* <div className="forms-login-container">
        <div className="signin-signup">
          {buyer ? (
            
          ) : (
            
          )}
        </div>
      </div>

      <div className="panels-login-container">
        {buyer ? (
          <div className="panel left-panel">
            <div className="content">
              <h4>
                <b>Buy Through us</b>
              </h4>
              <ul className="d-flex flex-column align-items-start">
                <li>
                  <i
                    className="fa fa-circle"
                    aria-hidden="true"
                    style={{ fontSize: 12, marginRight: 5 }}
                  ></i>
                  <strong
                    className="me-3"
                    style={{ fontWeight: 600, fontSize: 14 }}
                  >
                    Quality-Highest
                  </strong>
                </li>
                <li>
                  <i
                    className="fa fa-circle"
                    aria-hidden="true"
                    style={{ fontSize: 12, marginRight: 5 }}
                  ></i>
                  <strong
                    className="me-3"
                    style={{ fontWeight: 600, fontSize: 14 }}
                  >
                    Cost-Lowest
                  </strong>
                </li>
                <li>
                  <i
                    className="fa fa-circle"
                    aria-hidden="true"
                    style={{ fontSize: 12, marginRight: 5 }}
                  ></i>
                  <strong
                    className="me-3"
                    style={{ fontWeight: 600, fontSize: 14 }}
                  >
                    Delivery-Quickest
                  </strong>
                </li>
              </ul>
             
            </div>
            <img
              src={require("../../assets/images/loginbg.png")}
              className="image"
              alt=""
            />
          </div>
        ) : (
          <div className="panel left-panel">
            <div className="content">
              <h4>
                <b>Sell Through Us</b>
              </h4>
              <ul className="d-flex flex-column align-items-start">
                <li>
                  <i
                    className="fa fa-circle"
                    aria-hidden="true"
                    style={{ fontSize: 12, marginRight: 5 }}
                  ></i>
                  <strong
                    className="me-3"
                    style={{ fontWeight: 600, fontSize: 14 }}
                  >
                    Get Visibility
                  </strong>
                </li>
                <li>
                  <i
                    className="fa fa-circle"
                    aria-hidden="true"
                    style={{ fontSize: 12, marginRight: 5 }}
                  ></i>
                  <strong
                    className="me-3"
                    style={{ fontWeight: 600, fontSize: 14 }}
                  >
                    Get Branded
                  </strong>
                </li>

                <li>
                  <i
                    className="fa fa-circle"
                    aria-hidden="true"
                    style={{ fontSize: 12, marginRight: 5 }}
                  ></i>
                  <strong
                    className="me-3"
                    style={{ fontWeight: 600, fontSize: 14 }}
                  >
                    Go Global
                  </strong>
                </li>
              </ul>
            
            </div>
            <img
              src={require("../../assets/images/loginbg.png")}
              className="image"
              alt=""
            />
          </div>
        )}
      </div> */}
      <ToastContainer />
    </div>
  );
};

export default Register;
