import React from "react";

const Disclaimer = () => {
  return (
    <section id="data-protection" className="about-area ptb-80 bg-ffffff">
      <div className="container mt-5">
        <div className="section-title">
          <h2>
            Cookie <span>Policy</span>
          </h2>
        </div>

        <div className="row">
          <p>
            This Cookie Policy explains how ____ ("Company," "we," "us," and
            "our") uses cookies and similar technologies to recognize you when
            you visit our website at https://globalfactory365.com ("Website").
            It explains what these technologies are and why we use them, as well
            as your rights to control our use of them. In some cases we may use
            cookies to collect personal information, or that becomes personal
            information if we combine it with other information. What are
            cookies? Cookies are small data files that are placed on your
            computer or mobile device when you visit a website. Cookies are
            widely used by website owners in order to make their websites work,
            or to work more efficiently, as well as to provide reporting
            information. Cookies set by the website owner (in this case, ____)
            are called "first-party cookies." Cookies set by parties other than
            the website owner are called "third-party cookies." Third-party
            cookies enable third-party features or functionality to be provided
            on or through the website (e.g., advertising, interactive content,
            and analytics). The parties that set these third-party cookies can
            recognize your computer both when it visits the website in question
            and also when it visits certain other websites. Why do we use
            cookies? We use first- and third-party cookies for several reasons.
            Some cookies are required for technical reasons in order for our
            Website to operate, and we refer to these as "essential" or
            "strictly necessary" cookies. Other cookies also enable us to track
            and target the interests of our users to enhance the experience on
            our Online Properties. Third parties serve cookies through our
            Website for advertising, analytics, and other purposes. This is
            described in more detail below. How can I control cookies? You have
            the right to decide whether to accept or reject cookies. You can
            exercise your cookie rights by setting your preferences in the
            Cookie Consent Manager. The Cookie Consent Manager allows you to
            select which categories of cookies you accept or reject. Essential
            cookies cannot be rejected as they are strictly necessary to provide
            you with services. The Cookie Consent Manager can be found in the
            notification banner and on our website. If you choose to reject
            cookies, you may still use our website though your access to some
            functionality and areas of our website may be restricted. You may
            also set or amend your web browser controls to accept or refuse
            cookies. The specific types of first- and third-party cookies served
            through our Website and the purposes they perform are described in
            the table below (please note that the specific cookies served may
            vary depending on the specific Online Properties you visit):
            <br />
            <br />
    
            Contact Us If you have any questions about this Privacy Policy, You
            can contact us: By email: info@globalfactory365.com
          </p>
        </div>
      </div>
    </section>
  );
};

export default Disclaimer;
