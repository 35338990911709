import React from 'react';

import { Navigate } from "react-router-dom";
import LocalStorage from "../utils/LocalStorage";

const ProtectedRoute = ({ children }) => {
  const user = LocalStorage.getUserDetails();
  let isAuthenticated = false;
  if (user) {
    isAuthenticated = true;
  }

  return <div>{isAuthenticated === false ? <Navigate to="/" /> : children}</div>;
};

export default ProtectedRoute;