import React, { useState } from "react";
import "../../assets/css/login.css";
import { userLogin } from "../../axios/ServerRequest";
import LocalStorage from "../../utils/LocalStorage";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loading, setLoading] = useState(false);

  const loginUser = async () => {
    if (email.length < 10) {
      setEmailError("Please enter valid Email");
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("Please enter valid Email");
      return;
    } else if (password.length < 6) {
      setPasswordError("Please enter 6 characters’  password");
      return;
    } else {
      setLoading(true);
      await userLogin({ email, password })
        .then((res) => {
          if (res.status === 200) {
            const data = res.data;
            LocalStorage.setApiKey(data.token_type + " " + data.token);
            LocalStorage.setUserDetails(data.user);
            localStorage.setItem("message", "You have Successfully Loggedin. ");
            window.location.href = "/";
          }
          setLoading(true);
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            if (err.response.data.email) {
              setEmailError(err.response.data.email);
            } else if (err.response.data.password) {
              setPasswordError(err.response.data.password);
            }
          }
          setLoading(false);
        });
    }
  };

  const removeError = () => {
    setEmailError("");
    setPasswordError("");
  };

  return (
    <div className="login-banner pb-5">
      <div className="container">
        <div className="mt-5">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <img
                src={require("../../assets/images/loginbg.png")}
                className="img-fluid"
              />
            </div>
            <div className="col-md-6">
              <div className="card p-3">
                <div className="text-center mt-3">
                  <img
                    src={require("../../assets/images/logo.png")}
                    className="img-fluid"
                    style={{ maxWidth: 150 }}
                  />
                </div>
                <h5 className="text-center mt-3 mb-2" style={{ fontSize: 14 }}>
                  <b>Welcome to the manufacturing marketplace! </b>
                </h5>
                <h6 className="text-center mb-5" style={{ fontSize: 12 }}>
                  Please Signin to Continue
                </h6>
                <div>
                  <div className="form-group mb-3">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email"
                      autoComplete="off"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      autoFocus
                    />
                    <div className="fs-small text-danger">{emailError}</div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Password"
                      autoComplete="off"
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                    <div className="fs-small text-danger">{passwordError}</div>
                  </div>
                  <div className="mb-4">
                    <a href="#" className="float-end">
                      Forgot Password?
                    </a>
                  </div>
                  <div className="d-flex flex-row align-items-center flex-wrap mb-5">
                    <button
                      disabled={loading}
                      type="submit"
                      className="btn btn-primary"
                      onClick={() => {
                        removeError();
                        loginUser();
                      }}
                    >
                      {loading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        " Submit"
                      )}
                    </button>
                    <div className="ms-2 fs-12">
                      Don't have an account? <a href="/register">Register</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
