import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-toastify";
import "../../assets/css/contactus.css";
import { contactUs } from "../../axios/ServerRequest";

const ContactUs = (props) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      name,
      email,
      phone,
      message,
    };
    setLoading(true);
    await contactUs(data)
      .then((res) => {
        if (res.status === 200) {
          toast.success(res.data.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <div className="contact-container">
      <div className="contact-form-container">
        <div className="contact-us">
          <div className="contact-header">
            <h1>&#9135;&#9135;&#9135;&#9135;&nbsp;&nbsp;CONTACT US</h1>
          </div>
          <div className="social-bar">
            {/* <ul>
              <li>
                <i className="fab fa-facebook-f"></i>
              </li>
              <li>
                <i className="fab fa-twitter"></i>
              </li>
              <li>
                <i className="fab fa-instagram"></i>
              </li>
              <li>
                <i className="fab fa-dribbble"></i>
              </li>
            </ul> */}
          </div>
        </div>
        <div className="header">
          <img
            className="mb-3"
            src={require("../../assets/images/logo.png")}
            style={{ height: 30, width: 90 }}
          />
          <h1>Let's get in touch</h1>
          <h2>Contact us to start your query!</h2>
          {/* <div className="row mt-3">
            <div className="col-md-6">
              <i className="fa fa-map-marker fa-2"></i>
              <h6> 5709 Victoria Rd, Mason, OH 45040, United States A 82 Noida
                    Sec - 57 , Uttar Pradesh India -201301</h6>
            </div>
           
            <div className="col-md-6">
              <i className="fa fa-envelope fa-2"></i>
              <h6>info@globalfactory365.com</h6>
            </div>
          </div> */}
        </div>

        <div className="contact-form">
          <form id="contact" method="post" onSubmit={handleSubmit}>
            <input
              placeholder="Name"
              type="text"
              value={name}
              required
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <input
              placeholder="Email"
              type="email"
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <input
              placeholder="Phone"
              type="text"
              value={phone}
              required
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />

            <textarea
              placeholder="Tell us about your project..."
              rows="4"
              value={message}
              required
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            ></textarea>

            <Button type="submit" variant="primary" disabled={loading}>
              {loading ? (
                <div>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="visually-hidden">Sending...</span>
                </div>
              ) : (
                <span>Send</span>
              )}
            </Button>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ContactUs;
