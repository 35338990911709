import API from "./API";

export const userLogin = async (data) => {
  return await API({
    method: "POST",
    url: "/api/v1/login",
    data: data,
  }).then((res) => {
    return res;
  });
};

export const userRegister = async (data) => {
  return await API({
    method: "POST",
    url: "/api/v1/register",
    data: data,
  }).then((res) => {
    return res;
  });
};

export const createRFQ = async (data) => {
  return await API({
    method: "POST",
    url: "/api/v1/user/quotations",
    data: data,
  }).then((res) => {
    return res;
  });
};

export const getRFQ = async (page) => {
  return await API({
    method: "GET",
    url: `/api/v1/user/quotations?page=${page}`,
  }).then((res) => {
    return res;
  });
};

export const getRFQDetails = async (id) => {
  return await API({
    method: "GET",
    url: `/api/v1/user/quotations/${id}`,
  }).then((res) => {
    return res;
  });
};

export const manufacturerRegister = async (data) => {
  return await API({
    method: "POST",
    url: "/api/v1/manufacturerRegister",
    data: data,
  }).then((res) => {
    return res;
  });
};

export const checkManufacturer = async (data) => {
  return await API({
    method: "POST",
    url: "/api/v1/checkManufacturer",
    data: data,
  }).then((res) => {
    return res;
  });
};

export const addPremium = async (data) => {
  return await API({
    method: "POST",
    url: "/api/v1/addPremium",
    data: data,
  }).then((res) => {
    return res;
  });
};

export const payment = async (data) => {
  return await API({
    method: "POST",
    url: "/api/v1/user/quotations/payment",
    data: data,
  }).then((res) => {
    return res;
  });
};

export const sendReplayMail = async (data) => {
  return await API({
    method: "POST",
    url: "/api/v1/user/quotations/mail",
    data: data,
  }).then((res) => {
    return res;
  });
};

export const getCountries = async () => {
  return await API({
    method: "GET",
    url: "/api/v1/countries",
  }).then((res) => {
    return res;
  });
};

export const getCategories = async () => {
  return await API({
    method: "GET",
    url: "/api/v1/categories",
  }).then((res) => {
    return res;
  });
};

export const getSetting = async () => {
  return await API({
    method: "GET",
    url: "/api/v1/setting",
  }).then((res) => {
    return res;
  });
};



export const contactUs = async (data) => {
  return await API({
    method: "POST",
    url: "/api/v1/contact-us",
    data: data,
  }).then((res) => {
    return res;
  });
};