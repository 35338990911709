import React, { useState, useEffect } from "react";
import { createRFQ, getCategories } from "../../axios/ServerRequest";
import LocalStorage from "../../utils/LocalStorage";
import { ToastContainer, toast } from "react-toastify";
import { Multiselect } from "multiselect-react-dropdown";

const RFQ = (props) => {
  const user = LocalStorage.getUserDetails();
  const [productName, setProductName] = useState("");
  const [productNameError, setProductNameError] = useState("");
  const [quantity, setQuantity] = useState("");
  const [quantityError, setQuantityError] = useState("");
  const [unit, setUnit] = useState("");
  const [unitError, setUnitError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [userName, setUserName] = useState(user.name);
  const [userNameError, setUserNameError] = useState("");
  const [email, setEmail] = useState(user.email);
  const [emailError, setEmailError] = useState("");
  const [phone, setPhone] = useState(user.phone);
  const [phoneError, setPhoneError] = useState("");
  const [address, setAddress] = useState();
  const [addressError, setAddressError] = useState("");
  const [loading, setLoading] = useState(false);

  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState("");
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchCategory();
  }, []);

  const fetchCategory = async () => {
    await getCategories()
      .then((res) => {
        if (res.status === 200) {
          setCategories(res.data.category);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const resetError = () => {
    setProductNameError("");
    setCategoryError("");
    setAddressError("");
    setDescriptionError("");
    setEmailError("");
    setPhoneError("");
    setUserNameError("");
    setUnitError("");
    setQuantityError("");
  };

  const resetData = () => {
    setProductName("");
    setDescription("");
    setCategory("");
    setUnit("");
    setQuantity("");
  };

  const submitRFQ = async () => {
    if (productName.length < 3) {
      setProductNameError("Please enter valid Product name");
      return;
    } else if (quantity < 1) {
      setQuantityError("Please enter valid Quantity");
      return;
    } else if (unit.length === 0) {
      setUnitError("Please enter valid unit");
      return;
    } else if (category.length === 0) {
      setCategoryError("Please Select Category");
      return;
    } 
    else if (description.length === 0) {
      setDescriptionError("Please enter valid Requirement details");
      return;
    } else if (userName.length < 3) {
      setUserNameError("Please enter valid name");
      return;
    } else if (email.length < 10) {
      setEmailError("Please enter valid email");
      return;
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError("Please enter valid Email");
      return;
    } else if (phone.length < 10) {
      setPhoneError("Please enter valid phone");
      return;
    } else if (address.length < 10) {
      setAddressError("Please enter valid Address");
      return;
    } else {
      setLoading(true);
      const data = {
        product_name: productName,
        description: description,
        quantity: quantity,
        unit: unit,
        category: category,
        user_name: userName,
        phone: phone,
        email: email,
        address: address,
      };

      await createRFQ(data)
        .then((res) => {
          console.log(res.data);
          if (res.status === 201) {
            toast.success("You have successfully Raised your RFQ", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            resetData();
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };


  function onSelectCategory(selectedList, selectedItem) {
    let categoryList = selectedList.map((item) => {
      return item["title"];
    });
    setCategory(categoryList.toString());
  }

  function onRemoveCategory(selectedList, removedItem) {
    let categoryList = selectedList.map((item) => {
      return item["title"];
    });
    setCategory(categoryList.toString());
  }

  return (
    <div className="login-banner pt-5">
      <div className="container mt-5" style={{ minHeight: "100vh" }}>
        <div className="row">
          <div className="col-md-5" style={{ paddingTop: "10%" }}>
            <img src={require("../../assets/images/ecommerce.png")} />
          </div>
          <div className="col-md-7">
            <div className="card">
              <div className="card-body">
                <div className="text-center mt-3">
                  <img
                    src={require("../../assets/images/logo.png")}
                    className="img-fluid"
                    style={{ maxWidth: 150 }}
                  />
                </div>
                <h5 className="text-center mt-3 mb-2" style={{ fontSize: 14 }}>
                  <b>Welcome to the manufacturing marketplace! </b>
                </h5>
                <h6 className="text-center mb-5" style={{ fontSize: 12 }}>
                  Please raise your RFQ to get your product in best price.
                </h6>
                <div>
                  <div className="form-group mb-3">
                    <label>Product Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter product name"
                      value={productName}
                      onChange={(e) => {
                        setProductName(e.target.value);
                      }}
                    />
                    <div className="fs-small text-danger">
                      {productNameError}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Product Quantity</label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter product quantity"
                          value={quantity}
                          onChange={(e) => {
                            setQuantity(e.target.value);
                          }}
                        />
                        <div className="fs-small text-danger">
                          {quantityError}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>Product Unit</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="piece/pack/gm/kg/l"
                          value={unit}
                          onChange={(e) => {
                            setUnit(e.target.value);
                          }}
                        />
                        <div className="fs-small text-danger">{unitError}</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label>Categories</label>
                    {categories.length > 0 && (
                      <Multiselect
                        singleSelect
                        options={categories} // Options to display in the dropdown
                        onSelect={onSelectCategory} // Function will trigger on select event
                        onRemove={onRemoveCategory} // Function will trigger on remove event
                        displayValue="title" // Property name to display in the dropdown options
                      />
                    )}
                    <div className="fs-small text-danger">{categoryError}</div>
                  </div>

                  <div className="form-group mb-3">
                    <label>Requirement Details</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter requirement description"
                      rows={"5"}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      style={{ minHeight: 100 }}
                      value={description}
                    ></textarea>
                    <div className="fs-small text-danger">
                      {descriptionError}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label>User Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter user name"
                      value={userName}
                      onChange={(e) => {
                        setUserName(e.target.value);
                      }}
                    />
                    <div className="fs-small text-danger">{userNameError}</div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>User Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter user email"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                        <div className="fs-small text-danger">{emailError}</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group mb-3">
                        <label>User Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter user phone"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                          }}
                        />
                        <div className="fs-small text-danger">{phoneError}</div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <label>User Address</label>
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Enter Delivery Address"
                      rows={"3"}
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      style={{ minHeight: 70 }}
                      value={address}
                    ></textarea>
                    <div className="fs-small text-danger">{addressError}</div>
                  </div>

                  <button
                    disabled={loading}
                    type="submit"
                    className="btn btn-primary"
                    onClick={() => {
                      resetError();
                      submitRFQ();
                    }}
                  >
                    {loading ? (
                      <span className="spinner-border spinner-border-sm"></span>
                    ) : (
                      " Submit RFQ"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default RFQ;
