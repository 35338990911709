import React, { useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LocalStorage from "../../utils/LocalStorage";
import Profile from "../Profile";
import Quotation from "../Quotation";

const Dashboard = (props) => {
  const [user, setUser] = useState(LocalStorage.getUserDetails());
  const [selected, setSelected] = useState("profile");
  return (
    <div className="dashboard-banner pb-5 pt-5">
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-3 position-relative">
            <div className="card">
              <div className="card-body">
                <div className="text-center">
                  <img
                    src={require("../../assets/images/user.jpg")}
                    height={100}
                    width={100}
                  />
                  <div>
                    <strong>{user.name}</strong>
                  </div>
                  <p>{user.phone}</p>
                </div>
                <hr />
                <div
                  className={`p-3 cursor-pointer ${
                    selected === "profile" ? "menu-select" : ""
                  }`}
                  onClick={() => {
                    setSelected("profile");
                  }}
                >
                  <i className="fa fa-user-o" aria-hidden="true"></i>
                  <span> Profile</span>
                </div>

                <div
                  className={`p-3 cursor-pointer ${
                    selected === "quotation" ? "menu-select" : ""
                  }`}
                  onClick={() => {
                    setSelected("quotation");
                  }}
                >
                  <i className="fa fa-th-large" aria-hidden="true"></i>
                  <span> Quotations</span>
                </div>

                <div
                  className="p-3 cursor-pointer"
                  onClick={() => {
                    LocalStorage.logout();
                    window.location.href = "/";
                  }}
                >
                  <i className="fa fa-sign-out" aria-hidden="true"></i>

                  <span> Logout</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="card">
              <div className="card-body">
                {selected === "profile" ? <Profile /> : null}
                {selected === "quotation" ? <Quotation /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
